import React from 'react'
import Mynavbar from './Mynavbar'
import Footer from './Footer'

function Feedback() {
    return (
        <>
            
            <div className="feedback container">
                We Will Get Back to You
                with in 24 Hours
            </div>
            
        </>
    )
}

export default Feedback
